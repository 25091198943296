<template>
  <div class="content-wrap">
    <section class="questions-wrap">
      <div
        class="question-wrap-inner"
        v-for="test in test"
        :key="test.headline"
      >
        <div class="question">
          <div class="headline-block flex">
            <h3>{{ test.index + 1 }}.</h3>
            <div class="flex flex-col ml-8">
              <h3>{{ test.headline }}</h3>
              <p class="desc" v-if="!test.radio">
                (декілька правильних відповідей)
              </p>
            </div>
          </div>
          <div class="answers">
            <div
              class="row"
              v-for="(items, index) in test.testAnswers"
              :key="items.text"
            >
              <input
                :type="test.radio ? 'radio' : 'checkbox'"
                :id="'test_' + test.index + '_' + index"
                :name="'test_' + test.index"
                :value="items"
                v-model="answersArray[test.index]"
                @change="testAnswers"
              />
              <label :for="'test_' + test.index + '_' + index">{{
                items.text
              }}</label>
            </div>
          </div>
        </div>
        <hr v-if="!(test.index >= 9)" />
      </div>
    </section>
    <section class="form-wrap" v-if="totalAnswers === 10">
      <div
        class="not-correct-answers flex justify-center items-center"
        v-if="rightAnswers < 10"
      >
        <div class="text">
          <div class="test-result">{{ rightAnswers }} / 10</div>
          <p class="desc">
            Кількість правильних <br />
            відповідей
          </p>
        </div>
      </div>
      <div class="correct-answers form-wrap pb-8" v-if="rightAnswers === 10">
        <div class="text">
          <div class="test-result">{{ rightAnswers }} / 10</div>
          <p class="desc">
            Кількість правильних <br />
            відповідей
          </p>
        </div>
        <form
          @submit.prevent="formFunction"
          method="post"
          action="sendForm.php"
          ref="send_form"
          class="flex-col flex items-center"
        >
          <h4 class="form-head px-4 mb-10">
            Вітаємо, Ви справжній експерт у використанні препарату Адвокат®!
            Залиште свої контактні дані, аби ми могли з Вами зв’язатися.
          </h4>
          <div class="row">
            <input
              type="text"
              name="name"
              placeholder="Ім’я"
              v-model="$v.name.$model"
              :class="{ error: $v.name.$error }"
            />
          </div>
          <div class="row">
            <input
              type="text"
              name="surname"
              placeholder="Прізвище"
              v-model="$v.surname.$model"
              :class="{ error: $v.surname.$error }"
            />
          </div>
          <div class="row">
            <input
              type="text"
              name="telephone"
              placeholder="Телефон"
              v-model="$v.telephone.$model"
              :class="{ error: $v.telephone.$error }"
            />
          </div>
          <div class="row">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              v-model="$v.email.$model"
              :class="{ error: $v.email.$error }"
            />
          </div>
          <div class="row">
            <input
              type="text"
              name="zooShop"
              placeholder="Назва зоомагазину/ветклініки"
              v-model="$v.zooShop.$model"
              :class="{ error: $v.zooShop.$error }"
            />
          </div>
          <div class="row">
            <input
              type="text"
              name="address"
              placeholder="Адреса доставки подарунка"
              v-model="$v.address.$model"
              :class="{ error: $v.address.$error }"
            />
          </div>
          <div class="row">
            <input type="submit" />
          </div>
          <p>
            У випадку запитань телефонуйте <br />
            <a href="tel:+380972705847">+38 097 270 58 47</a> (Оксана Горобей)
          </p>
        </form>
        <div class="footer-text mt-14">
          <p>
            Advocate®, Адвокат®, ®, Elanco та діагональний логотип є товарними
            знаками Elanco або ii афілійованих осiб © 2020 Elanco та її
            афілійованi особи.
          </p>
          <p class="mt-6">
            За інформацією стосовно безпеки, ефективності та якості препарату
            звертайтеся за електронною адресою AE_PC_CIS@elanco.com
            PM-UA-20-0165
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from "axios";
import { helpers, minLength, email, required } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^\D*$/);
const phoneCorrect = helpers.regex(
  "phoneCorrect",
  /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
);

export default {
  name: "questions",
  data() {
    return {
      test: [
        {
          headline: "Блохи здатні переносити:",
          index: 0,
          radio: true,
          testAnswers: [
            { text: "Гельмінтів", value: false },
            { text: "Інфекційні захворювання", value: false },
            { text: "Алергени", value: false },
            { text: "Усе вищеперераховане", value: true }
          ]
        },
        {
          headline:
            "Для лікування отодектозу (вушного кліща) більшість крапель потрібно використовувати 14 днів поспіль двічі на день. Як саме Адвокат® дозволяє вирішити проблему отодектозу (вушного кліща):",
          index: 1,
          radio: true,
          testAnswers: [
            {
              text:
                "Разом із використанням місцевого антибактеріального препарату",
              value: false
            },
            { text: "За 2 застосування", value: false },
            { text: "За 1 застосування", value: true },
            {
              text:
                "За 1 застосування у поєднанні з обробкою оточуючого середовища",
              value: false
            }
          ]
        },
        {
          headline:
            "У випадку лікування демодекозу препарат Адвокат® можна використовувати з такою частотою:",
          index: 2,
          radio: false,
          testAnswers: [
            { text: "1 раз на тиждень", value: true },
            { text: "1 раз кожні 4 тижні", value: true },
            { text: "1 раз кожні 2 тижні", value: false }
          ]
        },
        {
          headline:
            "Стабільний рівень діючої речовини (моксидектину) дозволяє:",
          index: 3,
          radio: false,
          testAnswers: [
            {
              text: "Підтримувати функціональний стан нирок на належному рівні",
              value: false
            },
            {
              text:
                "Впродовж місяця захистити тварину від зараження дирофіляріозом",
              value: true
            },
            { text: "Досягнути тривалого лікувального ефекту", value: true },
            { text: "Захистити тварину від зараження блохами", value: false }
          ]
        },
        {
          headline:
            "У боротьбі із круглими гельмінтами (нематодами) препарат Адвокат® відрізняється від звичайного антигельмінтика завдяки тому, що:",
          index: 4,
          radio: false,
          testAnswers: [
            {
              text:
                "Захищає від широкого спектру нематод (серцевих, підшкірних, шлунково-кишкових, легеневих, очних та ін.)",
              value: true
            },
            { text: "Попереджає зараження дирофіляріями.", value: true },
            {
              text: "Знищує не лише дорослі, а й личинкові форми.",
              value: true
            },
            {
              text: "Можна застосовувати для івермектин чутливих порід собак.",
              value: true
            }
          ]
        },
        {
          headline: "З якого віку можна застосовувати препарат Адвокат®:",
          index: 5,
          radio: true,
          testAnswers: [
            {
              text: "Кошенятам – з 4 місяців, а цуценятам – з 6-ти.",
              value: false
            },
            {
              text: "Кошенятам з і цуценятам – з 3-х місячного віку.",
              value: false
            },
            { text: "Кошенятам з 9 тижнів, а цуценятам – з 7ми.", value: true }
          ]
        },
        {
          headline:
            "Вкажіть правильні твердження про лікування демодекозу собак препаратом Адвокат®:",
          index: 6,
          radio: false,
          testAnswers: [
            {
              text:
                "У всіх випадках схему лікування потрібно підбирати індивідуально із врахуванням відповіді ",
              value: true
            },
            {
              text:
                "Продовжувати лікування потрібно до отримання 2 послідовних зіскрібів зі шкіри",
              value: true
            },
            {
              text:
                "У важких випадках демодекозу рекомендовано більш часте (щотижнее) та триваліше використання препарату Адвокат®, що призводить до швидкого знищення кліщів демодекс",
              value: true
            },
            {
              text:
                "За допомогою препарату Адвокат® можливо вилікувати і локалізовану, і генералізовану форму демодекозу.",
              value: true
            },
            {
              text:
                "Оскільки генералізований демодекоз є багатофакторним захворюванням, то лікувати, окрім демодекозу, варто першопричину",
              value: true
            }
          ]
        },
        {
          headline:
            "Чи можна застосовувати препарат Адвокат® собакам цілорічно:",
          index: 7,
          radio: true,
          testAnswers: [
            {
              text:
                "Якщо собака проживає в неблагополучному стосовно дирофіляріозу регіоні, то  використовувати Адвокат® можна щомісяця впродовж року.",
              value: true
            },
            {
              text: "Так, можна щомісячно, але не більше 4 застосувань поспіль",
              value: false
            },
            { text: "Щомісячно в період льоту комарів", value: false },
            { text: "Ні, це не допустимо через токсичність", value: false }
          ]
        },
        {
          headline:
            "Відомо, що дирофілярії здатні жити в організмі собаки до 7-ми років. Скільки часу необхідно, щоб позбутися дорослих (імаго) серцевих дирофілярій за допомогою препарату Адвокат®?",
          index: 8,
          radio: true,
          testAnswers: [
            { text: "1 місяць", value: false },
            { text: "6 місяців", value: false },
            { text: "10 місяців", value: true },
            { text: "Це неможливо", value: false }
          ]
        },
        {
          headline:
            "1 блоха здатна кусати до 400 разів на день, а слина бліх містить більше десяти алергенів. Що є правдиво стосовно препарату Адвокат® у боротьбі з блохами?",
          index: 9,
          radio: false,
          testAnswers: [
            {
              text:
                "Швидке знищення дорослих бліх після застосування препарату (більше 60% ефективності через 6 годин після застосування, та 100% - через 12 годин).",
              value: true
            },
            {
              text: "Підходить для тварин із гіперчутливістю до укусів бліх.",
              value: true
            },
            { text: "Знищення і дорослих, і личинкових форм", value: true },
            {
              text: "Знищення яєць, а також дорослих та личинкових форм.",
              value: false
            },
            {
              text:
                "Контроль алергічного блошиного дерматиту та зменшення живлення бліх кров’ю улюбленця.",
              value: true
            }
          ]
        }
      ],
      answersArray: [null, null, [], [], [], null, [], null, null, []],
      rightAnswers: 0,
      totalAnswers: 0,
      name: null,
      surname: null,
      telephone: null,
      email: null,
      zooShop: null,
      address: null
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
      alpha
    },
    surname: {
      required,
      minLength: minLength(2),
      alpha
    },
    telephone: {
      required,
      phoneCorrect
    },
    email: {
      required,
      email
    },
    zooShop: {
      required
    },
    address: {
      required
    }
  },
  methods: {
    // saveAnswer(testNumber, value, ifRadio, answearNumberForCheckbox) {
    //   if (ifRadio) this.answersArray[testNumber] = value;
    //   else this.answersArray[testNumber][answearNumberForCheckbox] = value;
    // },
    testAnswers() {
      const sortedTestsResult = this.answersArray.map((item, i) => {
        if (item === null) return null;
        else if (Array.isArray(item) && item.length === 0) return null;
        else if (Array.isArray(item) && item.length > 0) {
          //array
          let rightAnswersInTest = this.test[i].testAnswers.filter(
            right => !!right.value
          ).length;
          let rightAnswersInAnswers = item.filter(right => !!right.value)
            .length;
          return (
            rightAnswersInTest === rightAnswersInAnswers &&
            item.every(el => !!el.value)
          );
        } else {
          return item.value;
        }
      });
      this.rightAnswers = sortedTestsResult.filter(
        resultItem => !!resultItem
      ).length;
      this.totalAnswers = sortedTestsResult.filter(
        answer => answer !== null
      ).length;
    },
    formFunction() {
      this.$v.$touch();
      if (this.$v.$invalid === false) {
        // const data = {
        //   name: this.name,
        //   surname: this.surname,
        //   telephone: this.telephone,
        //   email: this.email,
        //   zooShop: this.zooShop,
        //   address: this.address
        // };
        // axios.post("sendForm.php", data).then(() => {
        //   this.name = null;
        //   this.surname = null;
        //   this.telephone = null;
        //   this.email = null;
        //   this.zooShop = null;
        //   this.address = null;
        //   this.$v.$reset();
        // });
        this.$refs["send_form"].submit();
        this.name = null;
        this.surname = null;
        this.telephone = null;
        this.email = null;
        this.zooShop = null;
        this.address = null;
        this.$v.$reset();
      } else {
        alert("Заповніть всі поля вірно, будь ласка");
      }
    }
  }
};
</script>

<style>
.questions-wrap {
  padding-top: 20px;
}
.questions-wrap .question {
  padding: 40px 0;
  width: 90%;
  max-width: 570px;
  @apply mx-auto;
}
.questions-wrap .question .headline-block {
  @apply font-bold;
  margin-bottom: 35px;
}
.questions-wrap .question p.desc {
  color: #aaaaaa;
}
.questions-wrap .question .row {
  margin-bottom: 22px;
}
.questions-wrap .question .row:last-child {
  margin-bottom: 0;
}
.questions-wrap .question input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.questions-wrap .question input + label {
  @apply inline-flex items-center font-light;
  color: #222222;
}
.questions-wrap .question input + label::before {
  content: "";
  display: inline-block;
  margin-right: 25px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 2px solid #dddddd;
  border-radius: 50%;
}
.questions-wrap .question input:checked + label::before {
  box-shadow: 0 0 0 2px #dddddd;
  background: black;
  border: 5px solid white;
  transition: 0.3s all ease-in;
}

.form-wrap {
  margin-top: 40px;
}
.form-wrap .text {
  @apply text-center;
}
.form-wrap .not-correct-answers {
  border: 8px solid #f4b766;
  width: 90%;
  max-width: 570px;
  min-height: 250px;
  @apply mx-auto;
}
.form-wrap .text .test-result {
  font-size: 80px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 92px;
}
.form-wrap .text p.desc {
  color: #aaaaaa;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
}
.form-wrap .correct-answers {
  background-color: #eb905a;
  color: white;
  @apply text-center;
}
.form-wrap .correct-answers .text {
  padding: 80px 0;
}
.form-wrap .correct-answers .text p.desc {
  color: inherit;
  opacity: 0.75;
}
.form-wrap .correct-answers form {
  width: 90%;
  max-width: 570px;
  @apply mx-auto;
}
.form-wrap .correct-answers form .form-head {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}
.form-wrap .correct-answers form .row {
  @apply w-full mb-3;
  max-width: 430px;
}
.form-wrap .correct-answers form .row:last-child {
  @apply mb-6;
}
.form-wrap .correct-answers form .row > input {
  @apply w-full text-center px-4 text-lg font-semibold;
  height: 60px;
  border-radius: 43px;
  background-color: #d37a46;
  outline: none;
}
.form-wrap .correct-answers form .row > input.error {
  border: 1px solid rgba(255, 0, 0, 0.4);
}
.form-wrap .correct-answers form .row > input[type="submit"] {
  background-color: white;
  color: black;
}
.form-wrap .correct-answers form .row > input::placeholder {
  color: inherit;
  opacity: 0.49;
}
.form-wrap .correct-answers form p {
  max-width: 322px;
  font-size: 18px;
  /*font-weight: bold;*/
  line-height: 26px;
  text-align: center;
}
.form-wrap .correct-answers .footer-text {
  opacity: 0.5;
  max-width: 890px;
  @apply text-xs font-bold mx-auto;
}
@media (min-width: 1280px) {
  .questions-wrap {
    padding-top: 100px;
  }
}
</style>
