<template>
  <div id="app">
    <header class="tpz-head w-full">
      <div class="content-container mx-auto flex flex-col lg:flex-row items-end justify-between md:pt-12 pb-16 xl:h-full relative">
        <div class="col w-full flex flex-col md:flex-row lg:flex-col md:items-center lg:items-start justify-between xl:h-full">
          <div class="first-col">
            <div class="logo-wrap flex justify-between items-end">
              <div class="advocate logo mr-14"></div>
              <div class="elanco logo"></div>
            </div>
            <div class="text-wrap mt-6 md:mt-0">
              <h1 class="mb-5">
                Вітаємо Вас на Території Професійних Зоопродавців!
              </h1>
              <h2>
                Пропонуємо Вам перевірити свої знання стосовно препарату Адвокат®
                та отримати приємний подарунок за правильні відповіді – ручний
                прес для цитрусових. Грайте та насолоджуйтеся вітамінними напоями
                з нами!
              </h2>
            </div>
          </div>
          <div class="citrus-image mt-8 sm:hidden md:mt-0 md:block lg:absolute"></div>
        </div>
        <img
          src="./assets/img/cat_and_dog.jpg"
          alt="Вітаємо Вас на Території Професійних Зоопродавців"
          class="col animals-image hidden lg:block"
        />
      </div>
    </header>
    <questions></questions>
  </div>
</template>

<script>
import questions from "@/components/questions";

export default {
  name: "App",
  components: {
    questions
  }
};
</script>

<style>
* {
  font-family: Arial;
}
.tpz-head {
  background-color: #eb905a;
  height: auto;
  color: white;
  letter-spacing: 0;
  font-weight: bold;
}
.tpz-head h1 {
  font-size: 30px;
  line-height: 43px;
}
.tpz-head h2 {
  font-size: 18px;
  line-height: 24px;
}
.tpz-head .content-container {
  width: 90%;
  max-width: 1170px;
}
.tpz-head .content-container .logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.tpz-head .content-container .logo.advocate {
  background-image: url("./assets/img/Advocate_Logo.png");
  width: 180px;
  height: 42px;
}
.tpz-head .content-container .logo.elanco {
  background-image: url("./assets/img/Elanco_Logo.png");
  width: 163px;
  height: 81px;
}
.tpz-head .content-container .citrus-image {
  width: 100%;
  height: 262px;
  background: url("./assets/img/pres.png") center/contain no-repeat;
}
@media (min-width: 768px) {
  .tpz-head .content-container {
    width: 95%;
  }
  .tpz-head .content-container .citrus-image {
    min-width: 40%;
  }
}
@media (min-width: 1024px) {
  .tpz-head {
    height: 515px;
  }
  .tpz-head .content-container .col:first-child {
    max-width: 470px;
  }
  .tpz-head .content-container .citrus-image {
    width: 352px;
    height: 262px;
    bottom: -25%;
    left: calc((1170px - 352px) / 2);
  }
  .tpz-head .content-container .citrus-image {
    min-width: auto;
  }
  .tpz-head .content-container .animals-image {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .tpz-head .content-container .animals-image {
    width: 100%;
    max-width: 625px;
  }
}
</style>
